import Script from 'next/script'
import dynamic from 'next/dynamic'
import isEmpty from 'lodash/isEmpty'
import { useRef } from 'react'
import PropTypes from 'prop-types'
import { RichText } from 'prismic-reactjs'
// eslint-disable-next-line import/namespace
import { useModal } from '@ebay/nice-modal-react'

import startsWith from 'lodash/fp/startsWith'
import { useRouter } from 'next/router'
import { SkipNavLink, SkipNavContent } from '@chakra-ui/skip-nav'
import { namedComponent } from '@tofu/shared/utils/imports'
import { Flex } from '@tofu/shared/ui/atoms/flex'
import { Box } from '@tofu/shared/ui/atoms/box'
import { ErrorBoundary } from '@tofu/shared/ui/organisms/error-boundary'
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { HeaderDesktop } from '@tofu/shop/ui/organisms/header-desktop'
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { HeaderMobile } from '@tofu/shop/ui/organisms/header-mobile'
import TrackingScripts from '@tofu/apps/shop/components/common/tracking'
import Head from '@tofu/apps/shop/providers/head'
import { ShopPromoBanner } from '@tofu/shop/ui/organisms/shop-promo-banner'
import { useStaticContent } from '@tofu/shop/hooks/use-static-content'
import { useGlobalShopContent } from '@tofu/shop/hooks/use-global-shop-content'
import { Error } from '@tofu/shop/ui/organisms/error'
import { useSession } from '@tofu/apps/shop/providers/session'
// import { DrawerMobileMenu } from '@tofu/shop/ui/molecules/drawer-mobile-menu'

import { useIdentify } from '@tofu/apps/shop/hooks/rudderStack'

import { getPromoBannerTextArray, getFallbackTextArray } from './page.utils'

// const Header = dynamic(() => import('@tofu/apps/shop/components/layout/header'))
const Footer = dynamic(() =>
  namedComponent(import('@tofu/shop/ui/organisms/site-footer'), 'SiteFooter')
)

const DynamicDrawerMobileMenu = dynamic(() =>
  // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
  import('@tofu/shop/ui/molecules/drawer-mobile-menu').then(
    (mod) => mod.DrawerMobileMenu
  )
)

const startsWithAccount = startsWith('/account')

const PageLayout = ({
  children,
  description,
  is_hidden_page,
  meta_description,
  meta_image,
  meta_title,
  noIndex,
  path,
  title,
  headerProps
}) => {
  useIdentify()
  const mainRef = useRef()
  const router = useRouter()
  const { promoBanner } = useStaticContent()
  const { defaultPromoBanner } = useGlobalShopContent()
  const { isLoggedIn } = useSession()
  const drawerMobileMenu = useModal(DynamicDrawerMobileMenu)

  const { pathname } = router || {}
  const isNotIndexable = is_hidden_page || noIndex

  const isShopPickPage = startsWithAccount(pathname)

  const showHeader = !isShopPickPage

  const onClickMobileMenu = () => {
    drawerMobileMenu.show({ isLoggedIn })
  }

  const bannerText = getPromoBannerTextArray(promoBanner?.text)
  const fallbackBannerText = getFallbackTextArray(defaultPromoBanner?.text)

  const hasBannerText = !isEmpty(bannerText) || !isEmpty(fallbackBannerText)

  const { isBannerSticky, isBannerVisible, colorScheme } = promoBanner || {}

  return (
    <>
      <SkipNavLink
        sx={{
          zIndex: 1400 // to go over the menu, set to 1300
        }}
      >
        Skip to main content
      </SkipNavLink>
      <Head
        description={
          meta_description ? RichText.asText(meta_description) : description
        }
        image={meta_image}
        noIndex={isNotIndexable}
        path={path}
        title={meta_title ? RichText.asText(meta_title) : title}
      />
      <Script src='https://cdn.debugbear.com/nat6I3pcgQVS.js' async />

      {/* Show the banner outside the header if its not sticky  */}
      {hasBannerText && isBannerVisible && !isBannerSticky ? (
        <ShopPromoBanner
          defaultTextArray={fallbackBannerText}
          overrideTextArray={bannerText}
          colorScheme={colorScheme}
        />
      ) : null}

      <Flex
        flexDirection='column'
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 1300
        }}
        width='100%'
      >
        {/* Show the banner here if its sticky  */}
        {hasBannerText && isBannerVisible && isBannerSticky ? (
          <ShopPromoBanner
            defaultTextArray={fallbackBannerText}
            overrideTextArray={bannerText}
            colorScheme={colorScheme}
          />
        ) : null}

        {showHeader && (
          <>
            <Box display={['none', 'none', 'none', 'block']}>
              <HeaderDesktop
                isLoggedIn={isLoggedIn}
                headerProps={headerProps}
              />
            </Box>
            <Box display={['block', 'block', 'block', 'none']}>
              <HeaderMobile
                headerProps={headerProps}
                onClickMobileMenu={onClickMobileMenu}
              />
            </Box>
          </>
        )}
      </Flex>

      <main ref={mainRef} tabIndex='-1'>
        <div id='mmWrapperconversion'></div>
        <SkipNavContent />
        <ErrorBoundary fallback={<Error />}>{children}</ErrorBoundary>
      </main>
      <Footer />
      <TrackingScripts />
    </>
  )
}

PageLayout.defaultProps = {
  banner: undefined,
  description: undefined,
  is_hidden_page: false,
  meta_description: undefined,
  meta_image: {},
  meta_title: undefined,
  noIndex: false,
  path: undefined,
  title: undefined,
  headerBackground: 'white',
  buttonColorScheme: 'brandYellow',
  headerProps: {}
}

PageLayout.propTypes = {
  banner: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.node.isRequired,
  description: PropTypes.string,
  is_hidden_page: PropTypes.bool,
  meta_description: PropTypes.arrayOf(PropTypes.object),
  meta_image: PropTypes.shape({
    Facebook: PropTypes.object,
    Twitter: PropTypes.object,
    url: PropTypes.string
  }),
  meta_title: PropTypes.arrayOf(PropTypes.object),
  noIndex: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string,
  headerBackground: PropTypes.string,
  buttonColorScheme: PropTypes.string,
  headerProps: PropTypes.object
}

export default PageLayout
