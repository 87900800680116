import Link from 'next/link'
import { HamburgerIcon } from '@chakra-ui/icons'
import { Button } from '@tofu/shared/ui/atoms/button'
import { Box } from '@tofu/shared/ui/atoms/box'
import { Flex } from '@tofu/shared/ui/atoms/flex'
import { Container } from '@tofu/shared/ui/atoms/container'
import { VStack } from '@tofu/shared/ui/atoms/stack'
import { AllplantsLogo } from '@tofu/shared/ui/atoms/icons'
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { BasketIcon } from '@tofu/apps/shop/components/layout/basketIcon'

import { THeaderMobile } from './header-mobile.types'

export const HeaderMobile: THeaderMobile = ({
  headerProps,
  onClickMobileMenu
}) => {
  const basketIconNotificationBadgeColorScheme =
    headerProps?.bg === 'brandYellow' ? 'blackAlpha' : 'brandYellow'

  return (
    <Box bg='white' py={2} {...headerProps}>
      <Container maxW='container.xl'>
        <Flex justifyContent='space-between'>
          <Button onClick={onClickMobileMenu} as={Button} p={0} minWidth='auto'>
            <VStack spacing={-1}>
              <HamburgerIcon width='30px' height='30px' />
            </VStack>
          </Button>

          <Box left={0} right={0} m='0 auto' position='absolute' width='120px'>
            <Link href='/' passHref>
              <Button as='a' p={0}>
                <AllplantsLogo
                  aria-label='allPlants'
                  height='39px'
                  width='139px'
                  maxWidth='full'
                />
              </Button>
            </Link>
          </Box>

          <BasketIcon
            badgeColorScheme={basketIconNotificationBadgeColorScheme}
          />
        </Flex>
      </Container>
    </Box>
  )
}
