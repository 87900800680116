/* istanbul ignore file */
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable import/no-named-as-default */
import dynamic from 'next/dynamic'
import Link from 'next/link'

import { useBoolean } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { ChevronDownIcon } from '@chakra-ui/icons'

import { trackClick } from '@tofu/shared/utils/analytics'
import { Button } from '@tofu/shared/ui/atoms/button'
import { List, ListItem } from '@tofu/shared/ui/atoms/list'
import { UserCircleIcon, AllplantsLogo } from '@tofu/shared/ui/atoms/icons'
import { Container } from '@tofu/shared/ui/atoms/container'
// import { Link } from '@tofu/shop/ui/atoms/link'

import { Flex } from '@tofu/shared/ui/atoms/flex'
import BasketIcon from '@tofu/apps/shop/components/layout/basketIcon'

import { Box } from '@tofu/shared/ui/atoms/box'
import { HStack } from '@tofu/shared/ui/atoms/stack'
import { createUrlToAccount } from '@tofu/apps/shop/utils/redirect'

import { THeaderDesktop } from './header-desktop.types'

const DynamicHeaderDesktopShop = dynamic(() =>
  // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
  import('./header-desktop-shop/header-desktop-shop').then(
    (mod) => mod.HeaderDesktopShop
  )
)

// TODO
// Add to storybook.
// Blocked until <BasketIcon /> is moved into the libs dir :(

export const HeaderDesktop: THeaderDesktop = ({ headerProps, isLoggedIn }) => {
  const [showMenu, setShowMenu] = useBoolean(false)

  const basketIconNotificationBadgeColorScheme =
    headerProps?.bg === 'brandYellow' ? 'blackAlpha' : 'brandYellow'

  // debounce to prevent flickering as clicking the button it triggers the mouseEnter event
  // const setShowMenu.toggle = debounce(setShowMenu.toggle, 100)

  const handleOnShopMouseEnter = () => {
    if (!showMenu) {
      setShowMenu.toggle()
    }
  }

  // enable keyboard navigation for the shop menu when hitting space bar
  const handleOnShopKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === ' ') {
      e.preventDefault()
      setShowMenu.toggle()
    }
  }

  const accountText = isLoggedIn ? 'My Account' : 'Sign in'
  const accountUrl = isLoggedIn
    ? createUrlToAccount('/')
    : createUrlToAccount('/login')

  return (
    // TODO
    // change this hash colour to be dynamic based on the background, should be a light yellow when the header is yellow
    <Box
      as='header'
      bg='white'
      borderBottom='solid 1px #d8d8d8'
      {...headerProps}
    >
      <Container maxW='95%'>
        <HStack justifyContent='space-between'>
          <Link href='/' passHref>
            <Button as='a' variant='link'>
              <AllplantsLogo
                aria-label='allPlants'
                height='39px'
                width='139px'
                maxWidth='full'
              />
            </Button>
          </Link>

          <Box as='nav' aria-label='Main menu'>
            <Flex as={List}>
              <ListItem key='shop'>
                <Box onMouseLeave={setShowMenu.off}>
                  <Link href='/shop' passHref>
                    <Button
                      as='a'
                      onMouseEnter={handleOnShopMouseEnter}
                      onKeyDown={handleOnShopKeyDown}
                      // Using these tracking instead of the ones on link to keep amplitude data consistent
                      onClick={() =>
                        trackClick('header.shop', { label: 'Shop' })
                      }
                      rightIcon={
                        <motion.div
                          animate={{ rotate: showMenu ? 180 : 0 }}
                          transition={{
                            ease: 'easeInOut',
                            duration: showMenu ? 0.2 : 0.1
                          }}
                        >
                          <ChevronDownIcon />
                        </motion.div>
                      }
                      variant='link'
                      textStyle='body'
                      textDecoration='none'
                      fontWeight='regular'
                      p='20px 17px'
                      position='relative'
                      _hover={{
                        textDecoration: 'none'
                      }}
                    >
                      Shop Meals
                      <motion.div
                        style={{
                          position: 'absolute',
                          left: '0',
                          bottom: '0',
                          width: '100%',
                          background: '#000'
                        }}
                        initial={{ height: '0' }}
                        animate={{ height: showMenu ? '3px' : 0 }}
                        transition={{
                          ease: 'easeInOut',
                          duration: showMenu ? 0.2 : 0.1
                        }}
                      />
                    </Button>
                  </Link>
                  <Box
                    aria-hidden={showMenu ? 'false' : 'true'}
                    aria-expanded={showMenu}
                  >
                    <motion.aside
                      style={{
                        position: 'absolute',
                        height: '0',
                        left: '0',
                        width: '100%',
                        overflow: 'hidden',
                        visibility: showMenu ? 'visible' : 'hidden'
                      }}
                      initial={{ height: 0 }}
                      transition={{
                        ease: 'easeInOut',
                        duration: showMenu ? 0.2 : 0.1
                      }}
                      animate={{ height: showMenu ? 'auto' : 0 }}
                      exit={{
                        transition: { duration: 3 }
                      }}
                    >
                      <Box borderTop='solid 1px #d8d8d8'>
                        <DynamicHeaderDesktopShop
                          onMenuItemClick={setShowMenu.off}
                        />
                      </Box>
                    </motion.aside>
                  </Box>
                </Box>
              </ListItem>

              <ListItem key='bundles'>
                <Link href='/shop/bundles' passHref>
                  <Button
                    as='a'
                    variant='link'
                    textStyle='body'
                    textDecoration='none'
                    fontWeight='regular'
                    p='20px 17px'
                    _hover={{
                      textDecoration: 'none'
                    }}
                    onClick={() =>
                      trackClick('header.shop/bundles', {
                        label: 'shop bundles'
                      })
                    }
                  >
                    Bundles
                  </Button>
                </Link>
              </ListItem>

              <ListItem key='our-story'>
                <Link href='/our-story' passHref>
                  <Button
                    as='a'
                    variant='link'
                    textStyle='body'
                    textDecoration='none'
                    fontWeight='regular'
                    p='20px 17px'
                    _hover={{
                      textDecoration: 'none'
                    }}
                    onClick={() =>
                      trackClick('header.our-story', {
                        label: 'Our story'
                      })
                    }
                  >
                    About us
                  </Button>
                </Link>
              </ListItem>

              <ListItem key='how-it-works'>
                <Link href='/how-it-works' passHref>
                  <Button
                    as='a'
                    variant='link'
                    textStyle='body'
                    textDecoration='none'
                    fontWeight='regular'
                    p='20px 17px'
                    _hover={{
                      textDecoration: 'none'
                    }}
                    onClick={() =>
                      trackClick('header.how-it-works', {
                        label: 'How it works'
                      })
                    }
                  >
                    How it works
                  </Button>
                </Link>
              </ListItem>
            </Flex>
          </Box>

          <HStack spacing={0}>
            <Link href={accountUrl} passHref>
              <Button
                as='a'
                variant='link'
                padding='0 15px'
                textStyle='body'
                textDecoration='none'
                fontWeight='regular'
                leftIcon={<UserCircleIcon boxSize={6} />}
                _hover={{ textDecoration: 'none' }}
                onClick={() =>
                  trackClick('cta.header', {
                    label: { accountText }
                  })
                }
              >
                {accountText}
              </Button>
            </Link>
            {
              // TODO change this to use useModal, no embed like this :(
            }
            <BasketIcon
              badgeColorScheme={basketIconNotificationBadgeColorScheme}
            />
          </HStack>
        </HStack>
      </Container>

      {/* Overlay */}
      <motion.div
        style={{
          position: 'fixed',
          top: '100px',
          background: '#000000bf',
          height: 'var(--chakra-vh)',
          width: '100%',
          left: 0,
          zIndex: '-1',
          visibility: showMenu ? 'visible' : 'hidden'
        }}
        aria-hidden='true'
        transition={{
          ease: 'easeInOut',
          duration: showMenu ? 0.2 : 0.1
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: showMenu ? 1 : 0 }}
        onClick={setShowMenu.toggle}
      />
    </Box>
  )
}
